import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import styled from "styled-components"
import CategoryLabel from "../components/CategoryLabel"
import TagLabel from "../components/TagLabel"

const PostCardWrapper = styled.div`
  background: #fff;

  .post-card-link {
    /* padding: 1.4em 0; */
    /* padding-right: 1em; */
    color: #000;

    &:hover {
      color: ${(props) => props.theme.colors.highlight};
    }
    /* @media screen and (max-width: ${(props) =>
      props.theme.responsive.large}) {
      padding: 1em 0;
    } */
  }

  /* @media screen and (max-width: ${(props) => props.theme.responsive.small}) {
    padding: 0;
  } */
`

const PostCardContent = styled.div`
  display: grid;
  grid-template-columns: 270px 1fr;
  margin: 0;
  padding: 1.4em 20px;
  padding-right: 30px;
  /* width: calc(100% - 90px); */

  border-top: solid 1px ${(props) => props.theme.colors.blackLight};

  /* @media screen and (max-width: ${(props) =>
    props.theme.responsive.medium}) {
    grid-template-columns: 200px 1fr;
  } */

  @media screen and (min-width: ${(props) => props.theme.responsive.large}) {
    grid-template-columns: 324px 1fr;
    padding: 1.4em 20px;
  }

  @media screen and (max-width: ${(props) => props.theme.responsive.small}) {
    grid-template-columns: 1fr;
    padding: ${(props) => props.padding};

    /* border-top: solid 1px lightgray; */
    border-top: ${(props) => props.borderTop};
    background-color: ${(props) => props.backgroundColor};
  }

  .featured-image {
    grid-row: 1 / 4;
    grid-column: 1 / 2;
    margin: 4px 1em 0;
    /* width: 165px;
    height: 110px; */
    border-radius: 6px;

    @media screen and (min-width: ${(props) => props.theme.responsive.large}) {
      /* height: 150px; */
    }

    @media screen and (max-width: ${(props) => props.theme.responsive.small}) {
      /* width: 100px;
      height: 60px; */
      /* margin: 4px 6px; */
      /* margin-right: 10px; */
      display: ${(props) => (props.isImageShown ? "block" : "none")};
    }

    &:hover {
      opacity: 0.8;
    }
  }

  h3 {
    grid-row: 1 / 2;
    grid-column: 2 / 3;
    font-size: 1.2em;
    font-weight: 700;
    line-height: 1.4;
  }

  time {
    grid-row: 2 / 3;
    grid-column: 2 / 3;
    /* display: block; */
    margin-bottom: 0.2em;
    letter-spacing: 0.05em;
    font-size: 0.9em;
    color: ${(props) => props.theme.colors.gray};
  }

  @media screen and (max-width: ${(props) => props.theme.responsive.small}) {
    /* padding-left: 10px; */
    h3 {
      padding: 10px 16px;
      font-size: 16px;
    }
    time {
      display: none;
      /* font-size: 10px; */
    }
  }

  @media screen and (min-width: ${(props) =>
      props.theme.responsive.small}) and (max-width: ${(props) =>
      props.theme.responsive.medium}) {
    padding-left: 15px;
    h3 {
      font-size: 1em;
    }
    time {
      font-size: 10px;
    }
  }

  .labels {
    grid-row: 3 / 4;
    grid-column: 2 / 3;
    display: flex;
    flex-wrap: wrap;

    @media screen and (max-width: ${(props) => props.theme.responsive.small}) {
      display: none;
    }
  }
`

const PostDescription = styled.p`
  display: none;
  @media screen and (max-width: ${(props) => props.theme.responsive.small}) {
    display: ${(props) => (props.isDescShown ? "inline-block" : "0")};
    font-size: 0.8em;
    padding: 5px 20px;
  }
`

const Labels = styled.div``

const PostCard = ({ node, i }) => {
  const title = node.frontmatter.title || node.fields.slug
  const description = node.frontmatter.description
  const image = getImage(node.frontmatter.featuredImage)
  const tags = node.frontmatter.tags

  let borderTop = "solid 1px lightgray"
  // let isImageShown = false;
  let isImageShown = true
  let isDescShown = true
  let backgroundColor = "#fff"
  let padding = "1.3em 0"

  // 3枚おきに大きい画像
  // if (i === 1) {
  //   isImageShown = true;
  //   borderTop = "0";
  //   padding = "0.5em 0 1.3em";
  // }

  // if (4 <= i && i < 7) {
  //   backgroundColor = "#efefef"
  //   if (i === 4) {
  //     isImageShown = true
  //     borderTop = "0"
  //   }
  // }

  // if (i === 7) {
  //   borderTop = "0"
  //   isImageShown = true
  // }

  // if (10 <= i && i < 13) {
  //   backgroundColor = "#efefef"
  //   if (i === 10) {
  //     borderTop = "0"
  //     isImageShown = true
  //   }
  // }

  // if (13 <= i) {
  //   isImageShown = true
  //   isDescShown = false
  //   if (i === 13) { borderTop = "0" }
  // }

  return (
    <PostCardWrapper>
      <PostCardContent
        borderTop={borderTop}
        isImageShown={isImageShown}
        backgroundColor={backgroundColor}
        padding={padding}
      >
        <Link to={node.fields.slug} className="post-card-link">
          <GatsbyImage
            image={image}
            className="featured-image"
            alt={`image-${title}`}
          />
        </Link>
        <div>
          <Link to={node.fields.slug} className="post-card-link">
            <h3>{title}</h3>
          </Link>
          <PostDescription isDescShown={isDescShown}>
            {description}
          </PostDescription>
          <time>{node.frontmatter.date}</time>
          <Labels className="labels">
            <CategoryLabel slug={node.frontmatter.category} isLink="true" />
            {tags.map(({ tag }, index) => {
              return (
                <TagLabel
                  key={index}
                  slug={node.frontmatter.tags[index]}
                  isLink="true"
                />
              )
            })}
          </Labels>
        </div>
      </PostCardContent>
    </PostCardWrapper>
  )
}

export default PostCard
