import React from "react"
import Helmet from "react-helmet"
import { StaticQuery, graphql } from "gatsby"

const TagJsonLD = ({ tagSlug, tagName }) => {
  return (
    <StaticQuery
      query={jsonLdTagQuery}
      render={data => {
        const { siteUrl } = data.site.siteMetadata

        //bread crumbs
        const jsonBreadCrumbs = {
          "@context": "http://schema.org",
          "@type": "BreadcrumbList",
          itemListElement: [
            {
              "@type": "ListItem",
              position: 1,
              item: {
                "@id": siteUrl,
                name: "HOME",
              },
            },
            {
              "@type": "ListItem",
              position: 2,
              item: {
                "@id": `${siteUrl}/${tagSlug}`,
                name: tagName,
              },
            },
          ],
        }
        return (
          <Helmet>
            <script type="application/ld+json">
              {JSON.stringify(jsonBreadCrumbs)}
            </script>
          </Helmet>
        )
      }}
    />
  )
}

export default TagJsonLD

const jsonLdTagQuery = graphql`
  query jsonLdTagQuery {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
