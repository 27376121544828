import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import PostCard from "../components/PostCard"
import TagJsonLd from "../components/json/TagJsonLd"
import styled from "styled-components"

import svgTag from "../svg/others/tag.svg"

const Heading = styled.h1`
  margin: 0.5em 0 0.8em;
  font-size: 32px;
  color: #000;
  font-weight: 700;
  line-height: 44px;
  letter-spacing: 1px;
  text-align: center;
  img {
    position: relative;
    top: 5px;
    width: 32px;
    height: 32px;
    transform: scale(-1, 1);
    margin-right: 5px;
    /* vertical-align: middle; */
  }
`

class TagTemplate extends React.Component {
  render() {
    const { data, pageContext } = this.props
    const posts = data.allMarkdownRemark.edges
    // const { location } = this.props

    // get Tag name from tag slug
    const tagSlug = pageContext.tag
    const tagObject = data.site.siteMetadata.tags.find((tag) => {
      return tag.slug === tagSlug
    })

    // use slug when name doesn't exist
    const tagName = tagObject ? tagObject.name : tagSlug

    return (
      <Layout location={this.props.location} title={tagName}>
        <SEO title={tagName} />
        <TagJsonLd tagSlug={tagSlug} tagName={tagName} />
        <Heading>
          <img alt="" src={svgTag} />
          {tagName}
        </Heading>
        {posts.map(({ node }) => {
          return <PostCard key={node.fields.slug} node={node} />
        })}
      </Layout>
    )
  }
}

export default TagTemplate

export const pageQuery = graphql`
  query BlogPostByTag($tag: String) {
    site {
      siteMetadata {
        tags {
          name
          slug
          color
        }
      }
    }
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            date(formatString: "YYYY.MM.DD")
            title
            category
            tags
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 630) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
