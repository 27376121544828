import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import styled from "styled-components"

const Wrapper = styled.div`
  .tag-text {
    padding: 0.3em 1em;
    display: inline-block;
    display: inline;
    padding: 3px 6px;
    margin-right: 5px;
    line-height: 1.2;
    font-size: 12px;
    border-radius: 2px;
    font-weight: 700;
    color: #666;
    @media screen and (max-width: ${props => props.theme.responsive.large}) {
      font-size: 11px;
      padding: 2.5px 6px;
    }

    &:hover {
      opacity: 0.6;
    }
  }
`

const TagLabel = ({ slug, isLink }) => {
  if (!slug) return null
  return (
    <StaticQuery
      query={tagsQuery}
      render={data => {
        const { tags } = data.site.siteMetadata
        const tagObject = tags.find(tag => {
          return tag.slug === slug
        })

        const tagName = tagObject ? tagObject.name : slug
        // const tagColor = tagObject ? tagObject.color : "#eee"
        const content =
          isLink && tagObject ? (
            <Link
              to={`/tag/${slug}`}
              className="tag-text"
              style={{
                // background: tagColor,
                background: "#eee",
              }}
            >
              {tagName}
            </Link>
          ) : (
            <span
              className="tag-text"
              style={{
                // background: tagColor,
                background: "#eee",
              }}
            >
              {tagName}
            </span>
          )
        return <Wrapper>{content}</Wrapper>
      }}
    />
  )
}

const tagsQuery = graphql`
  query tagsQuery {
    site {
      siteMetadata {
        tags {
          name
          slug
          color
        }
      }
    }
  }
`

export default TagLabel
